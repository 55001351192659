<template>
  <div id="layout-page">
    <component :is="getThemeMode.layout" />
    <v-fade-transition v-if="enableLockScreen">
      <LockSereenModal />
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {
    LockSereenModal: () => import("@/layouts/component/PageLock.vue"),
  },
  data() {
    return {
      layoutName: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeMode", 'enableLockScreen']),
  },
  mounted() {
    if (localStorage.getItem("pageLock") && localStorage.getItem("pageLock") === 'true') {
      this.$store.commit('setLockScreenEnable', false);
      localStorage.removeItem("pageLock");
      this.$router.push('info');
    }
  }
};
</script>
